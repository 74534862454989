import React, { useState, useEffect, useContext } from 'react'
import Header from '../../components/Layout/Header'
import Sidebar from '../../components/Layout/Sidebar'
import Main from '../../components/Layout/Main'
import { Link } from 'react-router-dom'
import OrderContext from '../../context/bulk/orders/ordersContext'
import Spinner from '../../components/Layout/spinner'



const BulkOrders = () => {
    const orderContext = useContext(OrderContext);
    const { orders, getOrders, loading } = orderContext;


    useEffect(() => {
        getOrders()
    }, [])

    const page = 'Edit Customer'

    const [search, setSearch] = useState('')
    const onSearch = (e) => {

        setSearch(e.target.value)
    }




    return (


        <div>
            <Header />
            <Sidebar page={page} />
            <Main>


                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Bulk Orders</h5>
                        <input type="text" className="form-control" id="search" onChange={onSearch} name="search" placeholder="Search" />

                        {/* 
                CustomerName	TillNumber	TerminalName	ShiftNumber	RegistrationNumber	Product	PaymentType	Quantity	UnitPrice	RunningReading	AmountPaid	DispenserName	NozzleName	StorageLocation

                
                */}
                        <table className="table datatable">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">OrderId</th>
                                    <th scope="col">Customer Location</th>
                                    <th scope="col">Customer Name</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Status</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map((order, index) => (
                                    <tr key={order.OrderId}>
                                        <td>{index + 1}</td>
                                        <td>{order.OrderId}</td>
                                        <td>{order.CustomerLocation}</td>
                                        <td>{order.CustomerName}</td>
                                        <td>{order.Quantity}</td>
                                        <td>{order.Status}</td>
                                    </tr>
                                ))}
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </Main>
        </div>


    )
}

export default BulkOrders

