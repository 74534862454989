import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import PasswordResetModal from "../Layout/modals/passwordReset";
import AssignUserApp from "../Layout/modals/AssignUserApp";
import SweetAlertWrapper from "../Layout/SweetAlert";
import UserContext from "../../context/users/userContext";
import ChangeUserStatus from "../Layout/modals/ChangeUserStatus";
import Pagination from "../../components/Layout/paginator";

const Users = () => {
    const userContext = useContext(UserContext);
    const { fetchUsers, users, notification, clear_notifications } = userContext;

    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState("");
    const [showChangeUserStatusModal, setShowChangeUserStatusModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(10);
    const [action, setAction] = useState("");
    const [showAssignAppModal, setShowAssignAppModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });

    // Fetch users when the component mounts
    useEffect(() => {
        fetchUsers();
    }, []);

    // Handle search functionality
    useEffect(() => {
        if (search.trim() === "") {
            setFilteredUsers(users); // Reset to all users if search is empty
        } else {
            const filtered = users.filter((user) =>
                Object.values(user)
                    .join(" ")
                    .toLowerCase()
                    .includes(search.toLowerCase())
            );
            setFilteredUsers(filtered);
        }
        setCurrentPage(1); // Reset to first page when search changes
    }, [search, users]);

    // Handle notifications
    useEffect(() => {
        if (notification) {
            setAlertMessage({
                text: notification.text,
                type: notification.type,
            });
            setShowAlert(true);
        }
    }, [notification]);

    const handleConfirm = () => {
        setShowAlert(false);
        clear_notifications();
    };

    const handleShow = (userId) => {
        setSelectedUserId(userId);
        setShowModal(true);
    };

    const handleShowAssignApp = (userId) => {
        setSelectedUserId(userId);
        setShowAssignAppModal(true);
    };

    const handleShowChangeUserStatus = (userId, status) => {
        setSelectedUserId(userId);
        setAction(status ? "deactivate" : "activate");
        setShowChangeUserStatusModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setShowAssignAppModal(false);
        setShowChangeUserStatusModal(false);
    };

    const onSearch = (e) => {
        setSearch(e.target.value);
    };

    const onEdit = (id) => {
        navigate(`/users/edit/${id}`);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Paginate filtered users
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    // Calculate the total number of pages
    const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

    return (
        <div className="niceadmin">
            <Header />
            <Sidebar page="Users" />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={handleConfirm}
                    confirmBtnText="OK"
                    type={alertMessage.type || "info"}
                />
                <PasswordResetModal
                    show={showModal}
                    handleClose={handleClose}
                    userId={selectedUserId}
                />
                <AssignUserApp
                    show={showAssignAppModal}
                    handleClose={handleClose}
                    userId={selectedUserId}
                />
                <ChangeUserStatus
                    show={showChangeUserStatusModal}
                    handleClose={handleClose}
                    userId={selectedUserId}
                    action={action}
                />
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Users</h5>
                        <Link className="btn btn-primary" id="add-user" to="/users/create">
                            Add user
                        </Link>
                        <input
                            type="text"
                            className="form-control"
                            id="search"
                            onChange={onSearch}
                            name="search"
                            placeholder="Search"
                            value={search}
                        />
                        <table className="table datatable">
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Payroll Number</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentUsers.length > 0 ? (
                                    currentUsers.map((user, index) => (
                                        <tr key={index}>
                                            <td>{user.firstName}</td>
                                            <td>{user.lastName}</td>
                                            <td>{user.phoneNumber}</td>
                                            <td>{user.email}</td>
                                            <td>{user.payrollNumber}</td>
                                            <td>
                                                <span
                                                    className={`badge rounded-pill px-2 py-1 ${
                                                        user.isActive ? "bg-success text-white" : "bg-danger text-white"
                                                    }`}
                                                >
                                                    {user.isActive ? "Active" : "Inactive"}
                                                </span>
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-custom-actions dropdown-toggle"
                                                    type="button"
                                                    id="dropdownMenuButton"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    Actions
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                                    <li>
                                                        <button
                                                            className="dropdown-item d-flex align-items-center"
                                                            onClick={() => handleShow(user.userCode)}
                                                        >
                                                            <i className="bi bi-person"></i>
                                                            <span>Reset Password</span>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            className="dropdown-item d-flex align-items-center"
                                                            onClick={() =>
                                                                handleShowChangeUserStatus(user.userCode, user.isActive)
                                                            }
                                                        >
                                                            <i className="bi bi-gear"></i>
                                                            <span>
                                                                {user.isActive ? "Deactivate" : "Activate"}
                                                            </span>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            className="dropdown-item d-flex align-items-center"
                                                            onClick={() => onEdit(user.userCode)}
                                                        >
                                                            <i className="bi bi-pencil"></i>
                                                            <span>Edit</span>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            className="dropdown-item d-flex align-items-center"
                                                            onClick={() => handleShowAssignApp(user.userCode)}
                                                        >
                                                            <i className="bi bi-pen"></i>
                                                            <span>Assign Application</span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7" className="text-center">
                                            No users found.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {/* Always show pagination */}
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={handlePageChange}
                        />
                    </div>
                </div>
            </Main>
        </div>
    );
};

export default Users;
