import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import CustomersContext from "../../context/bulk/customers/customersContext";
import Select from "react-select";

const AddLocation = ({ show, handleClose, editLocation }) => {
    const customerContext = useContext(CustomersContext);
    const {
        addCustomerLocation,
        updateCustomerLocation,
        customers,
        getCustomers,
    } = customerContext;

    const [location, setLocation] = useState({
        customerId: "",
        locationName: "",
        latitude: "",
        longitude: "",
    });

    // Fetch customers on component mount
    useEffect(() => {
        getCustomers();
    }, [getCustomers]);

    // Preload editLocation details when `editLocation` or `show` changes
    useEffect(() => {
        if (show && editLocation) {
            setLocation({
                customerId: editLocation.customerId || "",
                locationName: editLocation.locationName || "",
                latitude: editLocation.latitude?.toString() || "",
                longitude: editLocation.longitude?.toString() || "",
            });
        } else if (!show) {
            // Reset the form when the modal is closed
            setLocation({
                customerId: "",
                locationName: "",
                latitude: "",
                longitude: "",
            });
        }
    }, [editLocation, show]);

    const onChange = (e) => {
        const { name, value } = e.target;
        setLocation({
            ...location,
            [name]: value,
        });
    };

    const handleCustomerChange = (selectedOption) => {
        setLocation({
            ...location,
            customerId: selectedOption ? selectedOption.value : "",
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (editLocation) {
            updateCustomerLocation(location);
        } else {
            addCustomerLocation(location);
        }
        handleClose(); // Close the modal after submitting
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {editLocation
                        ? "Edit Customer Location"
                        : "Add Customer Location"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="customerId">
                        <Form.Label>Select Customer</Form.Label>
                        <Select
                            name="customerId"
                            options={customers.map((customer) => ({
                                value: customer.customerId,
                                label: customer.customerName,
                            }))}
                            value={
                                customers
                                    .map((customer) => ({
                                        value: customer.customerId,
                                        label: customer.customerName,
                                    }))
                                    .find(
                                        (customer) =>
                                            customer.value ===
                                            location.customerId
                                    ) || null
                            }
                            onChange={handleCustomerChange}
                            isDisabled={!!editLocation} // Disable if editing
                        />
                    </Form.Group>
                    <Form.Group controlId="locationName">
                        <Form.Label>Location Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="locationName"
                            value={location.locationName}
                            onChange={onChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="latitude">
                        <Form.Label>Latitude</Form.Label>
                        <Form.Control
                            type="number"
                            name="latitude"
                            value={location.latitude}
                            onChange={onChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="longitude">
                        <Form.Label>Longitude</Form.Label>
                        <Form.Control
                            type="number"
                            name="longitude"
                            value={location.longitude}
                            onChange={onChange}
                            required
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        {editLocation ? "Update" : "Submit"}
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddLocation;
