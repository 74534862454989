import React, { useState, useEffect, useContext } from "react";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import { Link } from "react-router-dom";
import OrderContext from "../../context/bulk/orders/ordersContext";
import RemoveOrderModal from "../Orders/removeOrder";
import DeliveryModal from "../Orders/Deliver";
import ApproveOrderModal from "./ApprovalModal";
import ViewDeliveryPlanModal from "./viewDeliveryNote";
import { formatDate } from "../../api/util";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import SweetAlertWrapper from "../../components/Layout/SweetAlert";
import Pagination from "../../components/Layout/paginator";
import userCan from "../../config/user_can";

const ListOrders = () => {
    const orderContext = useContext(OrderContext);
    const { orders, getOrders, notification, clear_notification } =
        orderContext;

    useEffect(() => {
        getOrders();
    }, []);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });

    useEffect(() => {
        if (notification) {
            setAlertMessage({
                text: notification.text,
                type: notification.type,
            });
            setShowAlert(true);
        }
    }, [notification]);

    const page = "List Orders";

    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showDeliverModal, setShowDeliverModal] = useState(false);
    const [showApprovalModal, setShowApprovalModal] = useState(false);
    const [showViewDeliveryPlanModal, setShowViewDeliveryPlanModal] =
        useState(false);

    const [selectedOrderId, setSelectedOrderId] = useState("");
    const [approvalType, setApprovalType] = useState("");
    const [selectedOrder, setSelectedOrder] = useState({});

    const [search, setSearch] = useState("");
    const [statusFilter, setStatusFilter] = useState("");
    const [dateRange, setDateRange] = useState([null, null]);

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const handleShowCancel = (id) => {
        setSelectedOrderId(id);
        setShowCancelModal(true);
    };

    const handleShowDeliver = (id) => {
        setSelectedOrderId(id);
        setShowDeliverModal(true);
    };

    const handleShowApproval = (id, type) => {
        setApprovalType(type);
        setSelectedOrderId(id);
        setShowApprovalModal(true);
    };

    const handleClose = () => {
        setSelectedOrderId("");
        setShowCancelModal(false);
        setShowDeliverModal(false);
        setShowApprovalModal(false);
        setShowViewDeliveryPlanModal(false);
        clear_notification();
        setShowAlert(false);
        getOrders();
    };

    const handleShowDeliveryPlan = (id) => {
        setSelectedOrderId(id);
        setShowViewDeliveryPlanModal(true);
    };

    const onSearch = (e) => {
        setSearch(e.target.value);
    };

    const handleStatusFilterChange = (e) => {
        setStatusFilter(e.target.value);
    };

    const handleDateRangeChange = (dates) => {
        setDateRange(dates);
    };

    const filteredOrders = orders
        .filter(
            (order) =>
                order.customerName
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                order.customerPhone.includes(search.toLowerCase()) ||
                order.orderId.includes(search.toLowerCase()),
        )
        .filter((order) =>
            statusFilter ? order.statusName === statusFilter : true,
        )
        .filter((order) => {
            if (dateRange[0] && dateRange[1]) {
                const deliveryDate = new Date(order.expectedDeliveryDate);
                const startDate = new Date(dateRange[0]);
                const endDate = new Date(dateRange[1]);
                return deliveryDate >= startDate && deliveryDate <= endDate;
            }
            return true;
        });

    const downloadExcel = () => {
        const ws = XLSX.utils.json_to_sheet(filteredOrders, {
            header: [
                "customerName",
                "orderId",
                "customerPhone",
                "orderQuantity",
                "statusName",
                "expectedDeliveryDate",
                "productName",
            ],
            skipHeader: false,
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Orders");
        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        saveAs(
            new Blob([wbout], { type: "application/octet-stream" }),
            "orders.xlsx",
        );
    };

    // Determine if the button should be enabled
    const isDateRangeSelected = dateRange[0] && dateRange[1];

    // Calculate statistics
    const pendingStates = [
        "New Plan",
        "Assigned Orders",
        "Inventory Loaded",
        "Loaded",
    ];
    const totalOrders = filteredOrders.length;
    const totalDelivered = filteredOrders.filter(
        (order) => order.statusName === "Completed",
    ).length;
    const totalCancelled = filteredOrders.filter(
        (order) => order.statusName === "Cancelled",
    ).length;
    const totalPending = filteredOrders.filter(
        (order) =>
            order.statusName !== "Completed" &&
            order.statusName !== "Cancelled",
    ).length;

    // pagination calculation
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredOrders.slice(
        indexOfFirstItem,
        indexOfLastItem,
    );

    const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div>
            <Header />
            <Sidebar page={page} />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    message={alertMessage.text}
                    type={alertMessage.type}
                    confirmBtnText="OK"
                    onConfirm={() => handleClose()}
                />
                <RemoveOrderModal
                    show={showCancelModal}
                    deliveryPlanId={selectedOrderId}
                    handleClose={handleClose}
                />
                <DeliveryModal
                    show={showDeliverModal}
                    handleClose={handleClose}
                    orderId={selectedOrderId}
                />
                <ApproveOrderModal
                    show={showApprovalModal}
                    handleClose={handleClose}
                    orderId={selectedOrderId}
                    type={approvalType}
                />

                <ViewDeliveryPlanModal
                    show={showViewDeliveryPlanModal}
                    handleClose={handleClose}
                    deliveryPlanImage={selectedOrder.deliverNoteImage}
                />

                {/* Stats Section */}
                <div className="row">
                    <div className="col-xxl-3 col-md-6 mb-3">
                        <div
                            className="card bg-light"
                            style={{ padding: "5px", height: "120px" }}
                        >
                            <div
                                className="card-body"
                                style={{ padding: "5px" }}
                            >
                                <h5
                                    className="card-title"
                                    style={{ fontSize: "16px" }}
                                >
                                    Total Orders
                                </h5>
                                <p
                                    className="card-text"
                                    style={{ fontSize: "20px" }}
                                >
                                    {totalOrders}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-3">
                        <div
                            className="card bg-success text-white"
                            style={{ padding: "5px", height: "120px" }}
                        >
                            <div
                                className="card-body"
                                style={{ padding: "5px" }}
                            >
                                <h5
                                    className="card-title"
                                    style={{ fontSize: "16px" }}
                                >
                                    Delivered
                                </h5>
                                <p
                                    className="card-text"
                                    style={{ fontSize: "20px" }}
                                >
                                    {totalDelivered}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-3">
                        <div
                            className="card bg-warning text-white"
                            style={{ padding: "5px", height: "120px" }}
                        >
                            <div
                                className="card-body"
                                style={{ padding: "5px" }}
                            >
                                <h5
                                    className="card-title"
                                    style={{ fontSize: "16px" }}
                                >
                                    Pending
                                </h5>
                                <p
                                    className="card-text"
                                    style={{ fontSize: "20px" }}
                                >
                                    {totalPending}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-3">
                        <div
                            className="card bg-danger text-white"
                            style={{ padding: "5px", height: "120px" }}
                        >
                            <div
                                className="card-body"
                                style={{ padding: "5px" }}
                            >
                                <h5
                                    className="card-title"
                                    style={{ fontSize: "16px" }}
                                >
                                    Cancelled
                                </h5>
                                <p
                                    className="card-text"
                                    style={{ fontSize: "20px" }}
                                >
                                    {totalCancelled}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Orders Table</h5>
                        <Link
                            className="btn btn-primary"
                            id="add-user"
                            to="/bulk/orders/add"
                        >
                            Add Orders
                        </Link>

                        <div className="d-flex align-items-center mb-3">
                            <div className="flex-grow-1 me-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="search"
                                    onChange={onSearch}
                                    name="search"
                                    placeholder="Search"
                                />
                            </div>
                            <div className="flex-shrink-1 me-2">
                                <select
                                    className="form-control"
                                    id="statusFilter"
                                    value={statusFilter}
                                    onChange={handleStatusFilterChange}
                                >
                                    <option value="">All Statuses</option>
                                    <option value="Rejected">Rejected</option>
                                    <option value="New Order">New Order</option>
                                    <option value="Manager Approved">
                                        Manager Approved
                                    </option>
                                    <option value="Finance Approved">
                                        Finance Approved
                                    </option>
                                    <option value="Assigned Vehicle">
                                        Assigned Vehicle
                                    </option>
                                    <option value="Delivered">Delivered</option>
                                </select>
                            </div>
                            <div className="flex-shrink-1 me-2">
                                <DatePicker
                                    selected={dateRange[0]}
                                    onChange={handleDateRangeChange}
                                    startDate={dateRange[0]}
                                    endDate={dateRange[1]}
                                    selectsRange
                                    className="form-control"
                                    dateFormat="yyyy/MM/dd"
                                    placeholderText="Select Date Range"
                                />
                            </div>
                            <button
                                className="btn btn-success"
                                onClick={downloadExcel}
                                disabled={!isDateRangeSelected}
                            >
                                Download Excel
                            </button>
                        </div>

                        <table className="table datatable">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>

                                    <th scope="col">Order Id</th>
                                    <th scope="col">Customer</th>
                                    <th scope="col">Location</th>
                                    <th scope="col">Contact</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Delivery Date</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((order, index) => (
                                    <tr key={order.orderId}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{order.orderId}</td>
                                        <td>{order.customerName}</td>
                                        <td> {order.locationName}</td>
                                        <td>{order.customerPhone}</td>
                                        <td>{order.orderQuantity}</td>
                                        <td>
                                            <span
                                                className={`badge rounded-pill px-2 py-1 ${order.statusName ===
                                                    "New Order"
                                                    ? "bg-warning text-white"
                                                    : order.statusName ===
                                                        "Rejected"
                                                        ? "bg-danger text-white"
                                                        : order.statusName ===
                                                            "Manager Approved"
                                                            ? "bg-info text-white"
                                                            : order.statusName ===
                                                                "Finance Approved"
                                                                ? "bg-primary text-white"
                                                                : order.statusName ===
                                                                    "Assigned Vehicle"
                                                                    ? "bg-secondary text-white"
                                                                    : order.statusName ===
                                                                        "Delivered"
                                                                        ? "bg-success text-white"
                                                                        : ""
                                                    }`}
                                            >
                                                {order.statusName}
                                            </span>
                                        </td>
                                        <td>
                                            {formatDate(
                                                order.expectedDeliveryDate,
                                            )}
                                        </td>
                                        <td>{order.productName}</td>


                                        <td className="text-center">
    <div className="dropdown">
        <button
            className="btn btn-light btn-sm dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        >
            Actions
        </button>
        <ul
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton1"
        >
            {order.orderStatusId === 4 && (
                <li>
                    <Link
                        className="dropdown-item d-flex align-items-center"
                        onClick={() =>
                            handleShowDeliver(order.orderId)
                        }
                    >
                        <i className="bi bi-person"></i>
                        <span>Deliver Order</span>
                    </Link>
                </li>
            )}
            {order.orderStatusId !== 3 &&
                order.orderStatusId !== 0 &&
                order.orderStatusId !== 4 &&
                order.orderStatusId !== 5 && (
                    <li>
                        <Link
                            className="dropdown-item d-flex align-items-center"
                            onClick={() =>
                                handleShowApproval(order.orderId, "finance")
                            }
                        >
                            <i className="bi bi-person"></i>
                            <span>Finance Approve</span>
                        </Link>
                    </li>
                )}
            {order.orderStatusId !== 0 &&
                order.orderStatusId !== 2 &&
                order.orderStatusId === 1 && (
                    <li>
                        <Link
                            className="dropdown-item d-flex align-items-center"
                            onClick={() =>
                                handleShowApproval(order.orderId, "manager")
                            }
                        >
                            <i className="bi bi-person"></i>
                            <span>Manager Approve</span>
                        </Link>
                    </li>
                )}
            {order.statusName === "Delivered" &&
                order.deliverNoteImage !== "" ? (
                <li>
                    <Link
                        className="dropdown-item d-flex align-items-center"
                        onClick={() => {
                            setSelectedOrder(order);
                            handleShowDeliveryPlan(true);
                        }}
                        to="#"
                    >
                        <i className="bi bi-person"></i>
                        <span>View Delivery Note</span>
                    </Link>
                </li>
            ) : null}

            {/* Add "No Actions Available" as a default */}
            {!(
                order.orderStatusId === 4 ||
                (order.orderStatusId !== 3 &&
                    order.orderStatusId !== 0 &&
                    order.orderStatusId !== 4 &&
                    order.orderStatusId !== 5) ||
                (order.orderStatusId !== 0 &&
                    order.orderStatusId !== 2 &&
                    order.orderStatusId === 1) ||
                (order.statusName === "Delivered" &&
                    order.deliverNoteImage !== "")
            ) && (
                <li>
                    <span className="dropdown-item text-muted">
                        No Actions Available
                    </span>
                </li>
            )}
        </ul>
    </div>
</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Pagination
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            totalItems={orders.length}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </Main>
        </div>
    );
};

export default ListOrders;
